@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #0f172a;

  --card: #ffffff;
  --card-foreground: #0f172a;

  --popover: #ffffff;
  --popover-foreground: #0f172a;

  --primary: #306dcf;
  --primary-foreground: #f8fafc;

  --secondary: #f1f5f9;
  --secondary-foreground: #1e293b;

  --muted: #f1f5f9;
  --muted-foreground: #64748b;

  --accent: #f1f5f9;
  --accent-foreground: #1e293b;

  --destructive: #ef4444;
  --destructive-foreground: #f8fafc;

  --border: #e2e8f0;
  --input: #e2e8f0;
  --ring: #0f172a;

  --radius: 0.5rem;
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: Arial, Helvetica, sans-serif;
}