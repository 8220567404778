/* TipTap Editor Styles */

/* Basic editor styles */
.ProseMirror {
    outline: none;
}

.ProseMirror p {
    margin-bottom: 0.75em;
}

.ProseMirror>*+* {
    margin-top: 0.75em;
}

/* Placeholder */
.ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

/* Mentions */
.mention {
    border-radius: 0.375rem;
    padding: 0rem 0.375rem;
    margin: 0.125rem 0.125rem;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    display: inline-block;
    vertical-align: baseline;
    text-decoration: none;
    box-shadow: none;
    transition: opacity 0.2s ease;
    background-color: #e5e7eb !important; /* bg-gray-200 */
}

.mention:hover {
    opacity: 0.9;
}

/* Remove the type-specific background colors since we're using grey for all */
.mention[data-mention-type="PROJECT"],
.mention[data-mention-type="BATCH"],
.mention[data-mention-type="PRODUCTION_STAGE"],
.mention[data-mention-type="ORDER"],
.mention[data-mention-type="PROD_STAGE_TIMELINE"],
.mention[data-mention-type="USER"] {
    background-color: #e5e7eb !important; /* bg-gray-200 */
}

/* Task lists */
.ProseMirror ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
}

.ProseMirror ul[data-type="taskList"] li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5em;
}

.ProseMirror ul[data-type="taskList"] li>label {
    margin-right: 0.5rem;
    user-select: none;
}

.ProseMirror ul[data-type="taskList"] li>div {
    flex: 1;
}

/* Headings */
.ProseMirror h1 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.ProseMirror h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.ProseMirror h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

/* Lists */
.ProseMirror ul:not([data-type="taskList"]) {
    list-style-type: disc;
    padding-left: 1.5rem;
}

.ProseMirror ol {
    list-style-type: decimal;
    padding-left: 1.5rem;
}

/* Links */
.ProseMirror a {
    color: #2563eb;
    text-decoration: underline;
    cursor: pointer;
}

/* Code blocks */
.ProseMirror pre {
    background-color: #f1f5f9;
    border-radius: 0.25rem;
    padding: 0.75rem;
    font-family: monospace;
    overflow-x: auto;
}

/* Blockquotes */
.ProseMirror blockquote {
    border-left: 3px solid #e2e8f0;
    padding-left: 1rem;
    color: #64748b;
    font-style: italic;
}

/* Horizontal rule */
.ProseMirror hr {
    border: none;
    border-top: 2px solid #e2e8f0;
    margin: 1rem 0;
}

/* Tippy tooltip styling for mentions */
.tippy-box {
    border-radius: 0.5rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
    padding: 0;
    background-color: white;
    width: auto !important;
    max-width: 400px !important;
}

.tippy-box[data-theme~='light'] {
    background-color: white;
    color: #1f2937;
}

.tippy-box[data-theme~='custom'] {
    background-color: white;
    color: #1f2937;
    border-radius: 0.5rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
    padding: 0.25rem;
    max-height: 15rem;
    overflow-y: auto;
}

.tippy-box[data-animation='shift-away'][data-state='hidden'] {
    opacity: 0;
    transform: translateY(10px);
}

.tippy-content {
    padding: 0;
    width: 100%;
}

/* Images */
.ProseMirror img,
.ProseMirror .editor-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1rem 0;
    border-radius: 0.375rem;
    transition: outline 0.2s ease, outline-offset 0.2s ease;
}

/* Selected image styles */
.ProseMirror img.ProseMirror-selectednode,
.ProseMirror .editor-image.ProseMirror-selectednode {
    outline: 3px solid #2563eb;
    outline-offset: 2px;
}